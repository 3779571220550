.main-container {
  background: $light-grey-color;
  // height: 100vh;
  // min-height: 100vh;
  width: 100%;
  max-width: 1920px;
  padding: 0px;

  .wrapper {
    width: 100%;
    max-width: 350px;
  }

  .sidebar-main {
    width: 100%;
    max-width: 350px;
    // height: 100vh;
    // min-height: 980px;
    background: $white-color;
    // padding: 30px 30px 0px 50px;

    .sidebar-main-options-op {
      display: flex;
      align-items: center;
    }

    .sidebar-main-options-logo {
      width: 60px;
      height: 60px;
      margin-right: 10px;

      svg {
        width: 30px;
        height: 30px;
        fill: #8e8ea1;
      }
    }
  }
}

.new-category-popup {
  .event-date-data {
    .date-field {
      height: 40px;
      background-color: $white-color;
      border-radius: 10px;
      border: 1px solid $secondary-grey;
      padding-left: 16px;
    }

    .date-logo {
      width: 24px;
      height: 24px;
      right: 8px;
    }
  }

  .event-time-data {
    .event-time {
      height: 40px;
      background-color: $white-color;
      border-radius: 10px;
      border: 1px solid $secondary-grey;
      padding-left: 16px;
    }

    .event-logo {
      width: 24px;
      height: 24px;
      right: 8px;
    }
  }
}