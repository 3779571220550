.input-text--control {
  padding-left: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  // text-transform: capitalize;
  border: 1px solid $secondary-grey;
  height: 48px;

  &:focus {
    // border: none;
    border: 1px solid $secondary-grey;
    outline: none;
  }
}

// .input-email--control {
//     text-transform: lowercase !important;
// }

// Radio Button
.form-radio--control {
  position: relative;
  padding-left: 35px;
  margin: 12px 76px 12px 0;
  font-size: 13px;
  line-height: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 22px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      border: 2px solid #8bd7bc;
      background-color: white;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    // background-color: #eee;
    border-radius: 50%;
    border: 2px solid #a6a6a6;

    &:after {
      content: "";
      position: absolute;
      display: none;

      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      border-radius: 90%;
      background: #8bd7bc;
    }
  }

  &:hover {
    input~.checkmark {
      background-color: #fff;
    }
  }
}

// Textarea Design

.form-textarea--control {
  padding: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  border: 1px solid $secondary-grey;
  resize: none;
  min-height: 157px;
  margin-top: 20px;
  text-transform: capitalize;

  &:focus {
    outline: none;
    // border: none;
    border: 1px solid $secondary-grey;
  }
}

// Custom Input File Type Design

.input-file--control {
  padding: 5px;
  background-color: #f3f3f3;
  border-radius: 10px;
  text-transform: capitalize;
  border: none;
  width: 582px;

  .select-file--control {
    background-color: #8bd7bc;
    padding: 16px 34px;
    border-radius: 7px;
    max-width: 132px;
    transition: all 0.5s;

    &:hover {
      background-color: #66b79b;
    }

    input {
      display: none;
    }
  }

  .selected-value--control {
    margin-left: 14px;
    text-decoration: underline;
  }
}

.cancel-btn {
  min-width: 117px;
  height: 48px;
  border-radius: 10px;
  outline: none;
  border: 1.5px solid $blue-magenta;
  background-color: $white-color;
  color: $font-black-45;
  margin-right: 14px;
}

.submit-btn {
  min-width: 138px;
  height: 48px;
  border-radius: 10px;
  outline: none;
  border: 1.5px solid $light-green-color;
  background-color: $light-green-color;
}

.horizontal-lines {
  max-width: 1195px;
  width: 100%;
  border-top: 1px solid $secondary-grey;
  margin: 60px 0;
}

.password-field {
  width: 100%;
  height: 100%;
  // min-height: 400px;
  border-radius: 40px;
  background-color: $white-color;
  padding: 30px 60px;

  .label {
    margin-bottom: 10px;
  }

  input[type="password"] {
    width: 100%;
    max-width: 440px;
    height: 50px;
    border-radius: 40px;
    border: 1px solid $primary-grey;
    padding-left: 30px;
    outline: none;
  }

  .new-password {
    margin: 30px 0;
  }

  .update-button {
    margin-top: 30px;
    width: 100%;

    button {
      width: 100%;
      max-width: 210px;
      height: 45px;
      border-radius: 40px;
      outline: none;
      background-color: $primary-green;
      border: 1px solid transparent;
      font-size: 18px;
      color: $white-color;
      margin: 0 5px;
    }
  }
}

.model-view {
  div {
    div {
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        // h1 {
        //     text-align: left;
        // }

        .button {
          width: 100%;
          display: block;
          max-width: fit-content;
          flex-direction: row !important;
        }

        button {
          width: 150px;
          height: 50px;
          border-radius: 40px;
          border: 1px solid transparent;
          font-size: 18px;
          font-weight: 500;
          background-color: $primary-green;
          cursor: pointer;
          color: $dark-blue-color;
          margin: 0 5px;
        }

        .delete-data {
          background-color: #ff4a55;
          color: $white-color;
        }
      }
    }
  }

  .template--note,
  .folder--note {
    max-width: 370px;
    margin: 0 auto;
  }
}

// Custom DropDown Design

// /*the container must be positioned relative:*/
// .custom-select {
//     position: relative;
// }

// .custom-select select {
//     display: none;
//     /*hide original SELECT element:*/
// }

// .select-selected {
//     background-color: DodgerBlue;
// }

// /*style the arrow inside the select element:*/
// .select-selected:after {
//     position: absolute;
//     content: "";
//     top: 14px;
//     right: 10px;
//     width: 0;
//     height: 0;
//     border: 6px solid transparent;
//     border-color: #fff transparent transparent transparent;
// }

// /*point the arrow upwards when the select box is open (active):*/
// .select-selected.select-arrow-active:after {
//     border-color: transparent transparent #fff transparent;
//     top: 7px;
// }

// /*style the items (options), including the selected item:*/
// .select-items div,
// .select-selected {
//     color: #ffffff;
//     padding: 8px 16px;
//     border: 1px solid transparent;
//     border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
//     cursor: pointer;
//     user-select: none;
// }

// /*style items (options):*/
// .select-items {
//     position: absolute;
//     background-color: DodgerBlue;
//     top: 100%;
//     left: 0;
//     right: 0;
//     z-index: 99;
// }

// /*hide the items when the select box is closed:*/
// .select-hide {
//     display: none;
// }

// .select-items div:hover,
// .same-as-selected {
//     background-color: rgba(0, 0, 0, 0.1);
// }

/* Hide the browser's default radio button */
// .add-form--radio input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
// }

// /* custom radio button */
// .checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 22px;
//     width: 22px;
//     // background-color: #eee;
//     border-radius: 50%;
//     border: 2px solid #eee;
// }

/* On mouse-over, grey background color */
// .add-form--radio:hover input~.checkmark {
//     background-color: #fff;
// }

/* When the radio button is checked, add a blue background */
// .add-form--radio input:checked~.checkmark {
//     border: 2px solid #8BD7BC;
//     background-color: white;
// }

/* Create the indicator (the dot/circle - hidden when not checked) */
// .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
// }

/* Show the indicator (dot/circle) when checked */
// .add-form--radio input:checked~.checkmark:after {
//     display: block;
// }

/* Style the indicator (dot/circle) */
// .add-form--radio .checkmark:after {
//     top: 2px;
//     left: 2px;
//     width: 14px;
//     height: 14px;
//     border-radius: 90%;
//     background: #8BD7BC;
// }