* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: Now;
}

.container {
  width: 100%;
  max-width: 1620px;
  margin: 0 auto;
  padding: 0 20px;
}

.pointer {
  cursor: pointer;
}

.not--allowed {
  cursor: not-allowed;
}

.opacity--1 {
  opacity: 1;
}

.opacity--0 {
  opacity: 0;
}

.no--border {
  border: 0 !important;
}

.border-radius--circle {
  border-radius: $border-radius-circle !important;
}

.pointer__events--none {
  pointer-events: none !important;
}

.pointer__events--fill {
  pointer-events: fill !important;
}

.pointer--none {
  cursor: none !important;
}

.flex {
  @extend %flex;
}

.flex--column {
  @extend %flex--column;
}

.flex-column--reverse {
  @extend %flex-column--reverse;
}

.flex--row {
  @extend %flex--row;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row--reverse {
  @extend %flex--row-reverse;
}

.flex--wrap {
  @extend %flex--wrap;
}

.justify-content--center {
  @extend %justify-content--center;
}

.justify-content--end {
  @extend %justify-content--end;
}

.justify-content--start {
  @extend %justify-content--start;
}

.justify--start {
  justify-content: start !important;
}

.justify-content--between {
  @extend %justify-content--between;
}

.justify-content--around {
  @extend %justify-content--around;
}

.align-items--center {
  @extend %align-items--center;
}

.align-items--end {
  @extend %align-items--end;
}

.align-items--start {
  @extend %align-items--start;
}

.align-items--baseline {
  @extend %align-items--baseline;
}

.align-items--stretch {
  align-items: stretch;
}

.align-content--baseline {
  align-content: baseline;
}

.object__fit--cover {
  object-fit: cover;
}

.object__fit--contain {
  object-fit: contain;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

.text--center {
  text-align: center !important;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--underline {
  text-decoration: underline;
}

.text--capitalize {
  text-transform: capitalize;
}

.text--capital::first-letter {
  text-transform: uppercase;
}

.word--break {
  word-break: break-word;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.font--weight__normal {
  font-weight: normal;
}

.font--weight__300 {
  font-weight: $font-light;
}

.font--weight__400 {
  font-weight: $font-regular;
}

.font--weight__500 {
  font-weight: $font-medium;
}

.font--weight__600 {
  font-weight: $font-semi-bold;
}

.font--weight__700 {
  font-weight: $font-bold;
}

.font--weight__bold {
  font-weight: $font-bold;
}

.font--weight__800 {
  font-weight: $font-extra-bold;
}

.font--size__12 {
  font-size: 0.75rem;
}

.font--size__13 {
  font-size: 0.8125rem;
}

.font--size__14 {
  font-size: $font-size-sm;
}

.font--size__15 {
  font-size: 0.9375rem;
}

.font--size__16 {
  font-size: 1rem !important;
}

.font--size__18 {
  font-size: $font-size-lg !important;
}

.font--size__20 {
  font-size: 20px;
}

.font--size__22 {
  font-size: 22px;
}

.font--size__24 {
  font-size: $font-size-xl;
}

.font--size__30 {
  font-size: $font-size-el;
}

.font--size__35 {
  font-size: 35px;
}

.font--size__36 {
  font-size: 36px;
}

.font--size__50 {
  font-size: 50px;
}

.width--half {
  width: 50%;
}

.width {
  width: $full-width;
}

.height {
  height: $full-height;
}

.height-fill--available {
  height: auto;
  max-height: -webkit-fill-available;
}

.height--70 {
  height: 70px !important;
}

.text-decoration {
  text-decoration: none !important;
}

.black--color {
  color: $primary-black-color;
}

.secondary--black {
  color: $secondary-black;
}

.black--45 {
  color: $font-black-45;
}

.primary--black {
  color: $black-color;
}

.primary--blue {
  color: $primary-blue;
}

.secondary--blue {
  color: $secondary-blue;
}

.dark--blue-color {
  color: $dark-blue-color !important;
}

.medium--dark--grey-color {
  color: $medium-dark-grey-color;
}

.text--grey {
  color: $primary-grey;
}

.text--green {
  color: $primary-green;
}

.green {
  color: green;
}

.text--dark-grey {
  color: $secondary-grey;
}

.text--red {
  color: red;
}

.bg-light--blue {
  background: #e0ffff63 !important;
}

.light--grey-color {
  color: $light-grey-color;
}

.dark--black-color {
  color: $dark-black-color;
}

.background--green {
  background-color: $primary-green !important;
}

.bg--white {
  background-color: $white-color !important;
}

.bg--transparent {
  background-color: transparent;
}

.bg--blue {
  background-color: #f3ffff !important;
}

.line--height__13 {
  line-height: 13px;
}

.line--height__14 {
  line-height: 14px;
}

.line--height__16 {
  line-height: 16px;
}

.line--height__17 {
  line-height: 17px;
}

.line--height__21 {
  line-height: 21px;
}

.line--height__16 {
  line-height: 16px;
}

.line--height__18 {
  line-height: 18px;
}

.line--height__20 {
  line-height: 20px;
}

.line--height__22 {
  line-height: 22px;
}

.line--height__23 {
  line-height: 23px;
}

.line--height__24 {
  line-height: 24px;
}

.line--height__27 {
  line-height: 27px !important;
}

.line--height__29px {
  line-height: 29px !important;
}

.line--height__30px {
  line-height: 30px;
}

.line--height__33 {
  line-height: 33px;
}

.line--height__36 {
  line-height: 36px;
}

.line--height__38 {
  line-height: 38.5px;
}

.line--height__39 {
  line-height: 39px;
}

.line--height__50px {
  line-height: 50px;
}

.line--height__60px {
  line-height: 60px;
}

.font-size--10 {
  font-size: 0.625rem;
}

.line-height--14 {
  line-height: 14px;
}

.no--margin {
  @include margin(0px !important);
}

.mb--1 {
  @include marginBottom(1px !important);
}

.mb--10 {
  @include marginBottom(10px !important);
}

.mb--15 {
  @include marginBottom(15px !important);
}

.mb--18 {
  @include marginBottom(18px !important);
}

.mb--20 {
  @include marginBottom(20px !important);
}

.mb--23 {
  @include marginBottom(23px !important);
}

.mb--25 {
  @include marginBottom(25px !important);
}

.mb--30 {
  @include marginBottom(30px !important);
}

.mb--33 {
  @include marginBottom(33px !important);
}

.mb--35 {
  @include marginBottom(35px !important);
}

.mb--37 {
  @include marginBottom(37px !important);
}

.mb--40 {
  @include marginBottom(40px !important);
}

.mb--42 {
  @include marginBottom(42px !important);
}

.mb--43 {
  @include marginBottom(43px !important);
}

.mb--44 {
  @include marginBottom(44px !important);
}

.mb--46 {
  @include marginBottom(46px !important);
}

.mb--49 {
  @include marginBottom(49px !important);
}

.mb--50 {
  @include marginBottom(50px !important);
}

.mb--60 {
  @include marginBottom(60px !important);
}

.mb--51 {
  @include marginBottom(51px !important);
}

.mt--1 {
  @include marginTop(1px !important);
}

.mt--10 {
  @include marginTop(10px !important);
}

.mt--15 {
  @include marginTop(15px !important);
}

.mt--20 {
  @include marginTop(20px !important);
}

.mt--23 {
  @include marginTop(23px !important);
}

.mt--25 {
  @include marginTop(25px !important);
}

.mt--30 {
  @include marginTop(30px !important);
}

.mt--33 {
  @include marginTop(33px !important);
}

.mt--35 {
  @include marginTop(35px !important);
}

.mt--43 {
  @include marginTop(43px !important);
}

.mt--45 {
  @include marginTop(45px !important);
}

.mt--51 {
  @include marginTop(51px !important);
}

.mt--50 {
  @include marginTop(50px !important);
}

.mt--60 {
  @include marginTop(60px !important);
}

.mt--80 {
  @include marginTop(80px !important);
}

.mt-25 {
  @include marginTop(-25px);
}

.ml--1 {
  @include marginLeft(1px !important);
}

.ml--5 {
  @include marginLeft(5px !important);
}

.ml--10 {
  @include marginLeft(10px !important);
}

.ml--14 {
  @include marginLeft(14px !important);
}

.ml--20 {
  @include marginLeft(20px !important);
}

.ml--30 {
  @include marginLeft(30px !important);
}

.ml--40 {
  @include marginLeft(40px !important);
}

.mr--0 {
  @include marginRight(0px !important);
}

.mr--1 {
  @include marginRight(1px !important);
}

.mr--10 {
  @include marginRight(10px !important);
}

.mr--16 {
  @include marginRight(16px !important);
}

.mr--20 {
  @include marginRight(20px !important);
}

.mr--25 {
  @include marginRight(25px !important);
}

.mr--26 {
  @include marginRight(26px !important);
}

.mr--30 {
  @include marginRight(30px !important);
}

.mr--32 {
  @include marginRight(32px !important);
}

.mr--36 {
  @include marginRight(36px !important);
}

.mt--20 {
  @include marginTop(20px !important);
}

.no--padding {
  padding: 0px !important;
}

.pl--10 {
  padding-left: 10px;
}

.pl--25 {
  padding-left: 25px;
}

.pl--30 {
  padding-left: 30px;
}

.pr--10 {
  padding-right: 10px;
}

.pr--20 {
  padding-right: 20px;
}

.pr--30 {
  padding-right: 30px;
}

.pt--0 {
  @include paddingTop(0px !important);
}

.pt--1 {
  @include paddingTop(1px !important);
}

.pt--5 {
  @include paddingTop(5px !important);
}

.pt--6 {
  @include paddingTop(6px !important);
}

.pt--25 {
  @include paddingTop(25px !important);
}

.pb--15 {
  padding-bottom: 15px;
}

.pb--5 {
  padding-bottom: 5px;
}

.pb--25 {
  padding-bottom: 25px;
}

.pb--40 {
  padding-bottom: 40px;
}

.pb--80 {
  padding-bottom: 80px;
}

.p--0 {
  padding: 0;
}

.p--20 {
  padding: 20px;
}

.no--outline {
  outline: none !important;

  &:focus,
  &:active {
    outline: none !important;
  }
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  padding: 20px 0;
  margin: 0 !important;
  transition: all ease 1s;

  .modal-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $black-color;
    margin: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .smart-device {
    height: 96vh;
  }

  .smart-document {
    height: 100%;
  }
}

.device-title {
  height: 40px !important;
}

.red--border {
  border: 1px solid red !important;
}

.visibility {
  visibility: hidden;
}

.min-h--40 {
  min-height: 40px;
}

.min-h--70 {
  min-height: 70px;
}

.min-h--120 {
  min-height: 120px !important;
}

.height-60 {
  height: 60px;
}

.max-width--170 {
  max-width: 170px;
}

.max-width--180 {
  max-width: 180px;
}

.max-width--250 {
  max-width: 250px;
}

.max-width--800 {
  max-width: 800px;
}

.max-width--1178 {
  max-width: 1178px;
}

.max-width--1194 {
  max-width: 1194px;
}

.min-width--300 {
  min-width: 300px;
}

.width--5 {
  width: 5%;
}

.width--10 {
  width: 10%;
}

.width--13 {
  width: 13%;
}

.width--14 {
  width: 14%;
}

.width--18 {
  width: 18%;
}

.width--22 {
  width: 22%;
}

.width--30 {
  width: 30%;
}

.width--28 {
  width: 28%;
}

.width--72 {
  width: 72%;
}

.width--82 {
  width: 82%;
}

.width--86 {
  width: 86%;
}

.width--90 {
  width: 90%;
}

.btn {
  border-radius: 10px;
  min-width: 170px;
  height: 48px;
  padding: 0 20px;
  outline: none;
  border: none;

  &--primary {
    background-color: $primary-green;
  }
}

.grey--icon {
  path {
    stroke: $primary-grey !important;
    fill: unset !important;
  }
}

.multi-select--house {
  .select__control {
    padding: 0px !important;
    border: 0;
    height: auto !important;
    margin-top: 0px;
    max-width: 100%;
  }

  .css-12jo7m5 {
    padding: 0px;
    margin-right: 10px;
  }
}

.login-loader {
  border: 3px solid #f3f3f3;
  border-radius: 16px;
  border-top: 3px solid $primary-blue;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}