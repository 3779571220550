.login-wrapper {
  display: flex;
  flex-direction: row;
  max-height: 100vh;

  .public-img-wrapper {
    position: fixed;
    left: 0;
    height: 100vh;

    .home-image__wrapper {
      top: 330px;
      left: 355px;
      width: 320px;
      height: 214px;

      img {
        width: 131px;
        height: 158px;
      }
    }
  }

  .form__item {
    max-width: 600px;
    border-bottom: 1px solid $secondary-grey;

    input {
      border: none;
      outline: none;
      background-color: transparent;
      width: calc(100% - 30px);
      min-height: 45px;
      font-size: 15px;
      line-height: 18px;
      color: $black-color !important;

      &::placeholder {
        font-size: 15px !important;
        line-height: 18px !important;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }

    .show-password {
      height: 35px;
    }

    .show-passwords {
      height: 24px;
    }
  }

  .show--error {
    font-size: 13px;
    line-height: 13px;
    color: red;
    opacity: 1;
    visibility: visible;
    margin-top: 10px;
  }

  .login-btn {
    width: auto;
    min-width: 128px;
    height: auto;
    min-height: 48px;
    padding: 0 20px;
    border-radius: $border-radius-lg;
    outline: none;

    &:active,
    &:focus {
      outline: none !important;
    }
  }

  .form-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: scroll;
    min-height: auto;
    justify-content: center;

    .form-wrapper {
      width: 96%;
      max-width: 600px;
      margin: 0 auto;
      padding-top: 80px;

      .login--title {
        font-size: 22px;
        line-height: 27px;
        padding-top: 65px;

        span {
          font-size: 30px;
          line-height: 36px;
        }
      }

      .form__item {
        .user--icon {
          width: 14px;
        }

        .user--icon,
        .password--icon {
          height: 16px;
          margin-right: 15px;

          path {
            fill: $primary-blue;
          }
        }

        .password--icon {
          width: 12px;
        }

        .hide-password--icon,
        .show-password--icon {
          cursor: pointer;
          width: 24px;
          margin-bottom: -10px;

          g {
            path {
              fill: $black-color;
            }
          }
        }
      }

      .options-wrapper {
        max-width: 600px;

        .agree_check-box {
          width: 18px;
          height: 18px;
          border-radius: 3px;
          border: 1px solid $primary-blue;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          .correct--icon {
            width: 12px;
            height: 9px;

            path {
              fill: $primary-blue;
            }
          }
        }
      }

      .forgot-wrapper {
        max-width: 600px;

        .agree_check-box {
          width: 18px;
          height: 18px;
          border-radius: 3px;
          border: 1px solid $primary-blue;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          .correct--icon {
            width: 12px;
            height: 9px;

            path {
              fill: $primary-blue;
            }
          }
        }
      }
    }

    .footer-wrapper {
      width: 96%;
      max-width: 600px;
      margin: auto auto 30px auto;
    }
  }

  .home_name-wrapper {
    width: 96%;
    max-width: 600px;
    margin: auto;
    // margin-left: 40px;

    .add_house-image {
      width: 160px;
      height: 160px;
      border-radius: 20px;
      background: #f3f3f3;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .add_house--icon {
        width: 62px;
        height: 62px;
      }
    }

    .house--icon {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .image-upload-popup {
      min-width: 366px;
      background-color: $white-color;
      border-radius: 20px;
      box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.15);
      padding: 25px 27px;
      text-align: center;

      .title {
        font-size: 24px;
        line-height: 24px;
        font-weight: $font-bold;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 24px;
      }

      .add_house-image--label {
        border-radius: 10px;
        cursor: pointer;
        width: 312px;
        padding: 0 25px 18px 25px;
        border: 1px dashed $primary-blue;
        margin: 0 !important;

        .add_house--icon {
          width: 62px;
          height: 62px;
          margin: 50px 0;
        }
      }

      .profile-pic {
        width: 200px;
        height: 200px;
        border-radius: 6px;
        object-fit: cover;
        margin: 46px 0 25px 0;
      }
    }
  }

  .profile {
    width: 160px;
    height: 160px;
    border-radius: 20px;
  }
}

.login-wrapper .form__item input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px $light-grey-color inset !important;
}

.btn-wrapper {
  min-width: 312px;

  .add-img-btn {
    min-width: 138px;
  }

  .add-btn {
    width: auto;
    height: auto;
    min-height: 40px;
    border-radius: $border-radius-lg;
    outline: none;
  }

  .cancel-btn {
    width: auto;
    min-width: 148px;
    height: auto;
    min-height: 40px;
    font-weight: $font-medium;
    color: $primary-grey;
    border-radius: $border-radius-lg;
    border: 1px solid $primary-grey;
    outline: none;
    background-color: transparent;
  }

  .cancel-detail {
    max-width: 117px;
    border: 1.5px solid $blue-magenta;
    height: auto;
    min-height: 40px;
    font-weight: $font-medium;
    color: $font-black-45;
    border-radius: $border-radius-lg;
    outline: none;
    background-color: transparent;
  }
}

.account-detail {
  max-width: 1340px;
  margin: 0 auto;
  padding: 69px 0 0 0;

  .account-head {
    h1 {
      margin-top: 0px;
    }
  }
}

.profile-detail {
  .edit-profile {

    .edit-link,
    .password-link {
      padding-bottom: 18px;
      text-decoration: none;
    }

    .edit-link,
    .password-link:hover {
      color: $primary-grey;
    }

    .active {
      border-bottom: 5px solid $dark-blue-color;
      font-weight: 500 !important;
      color: $primary-blue;
    }
  }
}

.edit-profile-data {
  max-width: 1340px;
  margin: 0 auto;

  .img-list {

    // max-width: 160px;
    // max-height: 160px;
    .managed-by {
      width: 160px;
      height: 160px;
    }

    .img-wrapper {
      width: 160px;
      height: 160px;
      background: #f3f3f3;
      border: none;
      border-radius: 20px;
    }
  }

  .edit-image-detail {
    width: 40px;
    height: 40px;
    border: 2px solid $primary-blue;
    border-radius: 100px;
    top: -6px;
    right: -14px;

    .edit-logo {
      width: 18px;
      height: 18px;
    }
  }

  .edit-input-field {
    max-width: 892px;

    .edit-label {
      letter-spacing: 0.01em;
    }
  }

  .form__item {
    width: 100%;
    max-width: 890px;
    height: 50px;
    background-color: #f3f3f3;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    line-height: 13px;
    color: $black-color;
    padding: 11px 16px;
    border: 1px solid $secondary-grey;
    text-transform: capitalize;
  }

  .edit-email-field {
    max-width: 892px;
  }

  .profile-btn {
    max-width: 200px;

    .add-btn {
      min-width: 175px;
      height: 50px;
      border-radius: 10px;
    }
  }
}

.change-password {
  max-width: 1340px;
  margin: 0 auto;

  .form__item {
    max-width: 892px;
  }

  .password-field {
    width: 100%;
    // max-width: 343px;
    height: 50px;
    background-color: #f3f3f3;
    // border: none;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    line-height: 13px;
    color: $black-color;
    padding: 11px 16px;
    border: 1px solid $secondary-grey;
  }

  .field-error {
    padding: 10px 0 0;
  }

  .profile-btn {
    max-width: 238px;

    .add-btn {
      min-width: 200px;
      height: 50px;
      border-radius: 10px;
    }
  }

  .show-password {
    width: 24px;
    height: 24px;
    right: 13px;

    .show-password--icon {
      width: 24px;
      height: 24px;
    }
  }
}

.activation-button {
  min-width: 128px;
  // padding: 16px 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: $primary-green;
  border-radius: 10px;
}

.error-animation {
  margin: 0 auto;
  max-width: 800px;
  min-height: 400px;

  svg {
    max-height: 400px;
  }
}

.verify-setup,
.verify-text {
  max-height: 400px;
}

.hide-password--icon,
.show-password--icon {
  cursor: pointer;
  width: 24px;
  margin-bottom: -10px;

  g {
    path {
      fill: $black-color;
    }
  }
}

.terms-privacy--wrapper {
  height: 100%;

  .terms-privacy {
    height: 100%;
    margin-top: 80px;

    .side-bar--link {
      max-width: 20%;
    }

    .detail {
      max-width: 80%;
    }
  }
}