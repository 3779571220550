//Font family
$font: "AvenirLTStd",
sans-serif;

$white-color: #ffffff;
$black-color: #111111;
$primary-black-color: #000000;

$primary-green: #8bd8bd;
$primary-blue: #1f4173;
$dark-blue-color: #273773;
$secondary-blue: #243665;
$primary-grey: #666666;
$medium-dark-grey-color: #666666;
$light-grey-color: #f9f9f9;
$dark-black-color: #22242c;
$white-smoke-grey: #f5f5f5;
$blue-magenta: #999999;
$secondary-black: #18191a;
$secondary-grey: #c4c4c4;

$font-black-45: rgba(24, 25, 26, 0.45);
$light-green-color: #8bd8bd;

//Font Weight Variables
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 900;

//latter Spacing variables
$letter-spacing-base: 0.5px !default;
$letter-spacing-lg: 2px !default;
$letter-spacing-xl: 4px !default;

//Font size variables
$font-size-xs: 0.8125rem; //13px
$font-size-sm: 0.875rem; //14px
$font-size-default: 0.9375rem; // 0.9375rem = 15px
$font-size-browser-default: 1rem; //16px
$font-size-md: 1.0625rem; //17px
$font-size-lg: 1.125rem; //18px
$font-size-xl: 1.5rem; //24px
$font-size-el: 1.875rem; //30px

//Width and height variables
$full-width: 100%;
$full-height: 100%;
$full-viewport-width: 100vw;
$full-viewport-height: 100vh;

//Container width variables
$container-default-width: 80%;
$container-full-width: $full-width;
$container-max-width: 1200px;

//Border radius width variables
$border-radius-default: 5px;
$border-radius-sm: 2px;
$border-radius-md: 8px;
$border-radius-lg: 10px;
$border-radius-circle: 50%;