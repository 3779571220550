.contact-wrapper {
  width: 100vw;
  height: 100%;

  .form_phone {
    min-width: 517px;
  }

  .add_number--label {
    min-width: 151px;
    min-height: 40px;
    color: $primary-blue;
    border: 1px dashed $primary-blue;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 15px 0 0 25px;
  }

  .add-label-wrapper {
    max-width: 112px;
    margin: 0;
  }

  .area-info-wrapper {
    width: 840px;

    .form__item {
      min-width: 33.33% !important;
    }
  }

  .phone-number-wrapper {
    width: max-content;

    .delete_icon-wrapper {
      right: -13px;
      top: 3px;
    }

    &:hover {
      .delete_icon-wrapper {
        height: 30px;
        opacity: 1;
      }
    }
  }

  .label-wrapper {
    .label_name--list {
      display: flex;
    }
  }
}

.form__menu {
  border: 1px solid $secondary-grey;
  background-color: #f3f3f3;
  outline: none;
  border-radius: 10px;
  font-size: 13px;
  color: $primary-grey;
  min-width: 174px;
  height: 40px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  margin: 0px 25px 0 0;
  padding-left: 16px;
}

.form__item,
.form__menu {
  .down-arrow--icon {
    width: 10px;
    height: 10px;
    transition: all 0.3s linear;
  }

  &:hover {
    .down-arrow--icon {
      transform: rotateX(180deg);
    }
  }
}

.drop-down-wrapper {
  position: relative;

  .drop-down--select {
    .down-arrow--icon {
      transform: rotateX(180deg);
    }
  }

  .hide--options {
    min-height: 0;
    opacity: 0;
    height: 0;
  }

  .options-wrapper {
    position: absolute;
    // width: 100%;
    min-width: 200px;
    min-height: 100%;
    background-color: $white-color;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 19px 1px;
    transition: height 0.4s ease;
    opacity: 1;
    z-index: 2;

    .option {
      color: $primary-grey;
      background-color: $white-color;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 5px;
      transition: all 0.2s ease;

      &:hover {
        color: $white-color;
        background-color: $primary-blue;
      }
    }
  }
}

.field-error {
  padding: 0 0 5px 30px;
}

.add__contact-number {
  max-width: 892px;

  .add_number--label {
    max-width: 151px;
    height: 40px;
    border: 1px dotted $primary-blue;
    border-radius: 10px;
  }
}

.horizontal-line {
  max-width: 1192px;
  margin-left: 0px;
  background: $secondary-grey;
  height: 1px;
}

.horizontal {
  max-width: 1194px;
}

.address--wrapper {
  max-width: 892px;
}

.add-label-wrapper {
  max-width: 112px;
  height: 40px;
  border: 1px dotted $primary-blue;
  border-radius: 10px;
}

.contact-table {
  // padding: 59px 57px 42px 36px;
  min-width: 900px;
}

.contact__field-data {
  max-width: 1194px;

  .contact-phone--detail {
    .form__item {
      width: 100%;
      max-width: 890px;
      height: 48px;
      background-color: #f3f3f3;
      border: 1px solid $secondary-grey;
      outline: none;
      border-radius: 10px;
      // color: $primary-grey;
      color: $secondary-black;
      // padding: 0px 16px;
      padding: 0 30px 0 50px;
      font-size: 16px;
    }

    .flag-dropdown {
      border-radius: 10px 0px 0px 10px;
    }

    .react-tel-input .selected-flag:hover,
    .react-tel-input .selected-flag:focus {
      background-color: transparent;
    }
  }
}

// ======================contact-table-css======================
.contact-all-data {
  table {
    width: 100%;
  }

  .contact-table-head {
    border-bottom: 1px solid #e6eaf0;
    margin-bottom: 15px;
    height: 60px;

    .contact-name,
    .phone-number,
    .company-name,
    .action {
      width: 10%;
    }

    .email,
    .labels {
      width: 20%;
    }

    .address {
      width: 20%;
    }
  }

  .table__row-data {
    border-bottom: 1px solid #e6eaf0;
    height: 60px;

    .row-contact,
    .row-phone,
    .row-company,
    .row-action {
      width: 10%;
    }

    .row-action__list {
      visibility: hidden;
    }

    .row-email,
    .row-label {
      width: 20%;
    }

    .row-address {
      width: 100%;
      height: 60px;

      span {
        white-space: nowrap;
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: auto;
      }
    }

    .row-label {
      span {
        width: 100%;
        background-color: rgba(139, 216, 189, 0.5);
        border-radius: 20px;
        padding: 8px 24px;
      }
    }
  }
}

.contact-all-data {
  table {
    width: 100%;
  }

  .contact-table-head {
    border-bottom: 1px solid #e6eaf0;
    margin-bottom: 15px;
    height: 60px;

    .contact-name,
    .phone-number,
    .company-name,
    .action {
      width: 10%;
      text-align: start;
    }

    .email,
    .labels {
      width: 20%;
      text-align: start;
    }

    .address {
      width: 20%;
    }
  }

  .table__row-data {
    border-bottom: 1px solid #e6eaf0;
    height: 60px;

    .row-contact,
    .row-phone,
    .row-company,
    .row-action {
      width: 10%;
    }

    .row-action {
      visibility: hidden;
    }

    .row-email,
    .row-label {
      width: 20%;
    }

    .row-address {
      width: 100%;
      height: 60px;

      span {
        white-space: nowrap;
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: auto;
      }
    }

    .row-label {
      span {
        width: 100%;
        background-color: rgba(139, 216, 189, 0.5);
        border-radius: 20px;
        padding: 8px 24px;
      }
    }
  }
}

.action-data {

  .add-data-logo,
  .delete-data-logo {
    width: 24px;
    height: 24px;
  }
}

.home-transfer--icon {
  transform: rotate(270deg);
  fill: $primary-grey;
  width: 20px !important;
  height: 20px !important;
}