.category-wrapper {
  width: 100vw;
  height: 100%;
}

// .smart--device__wrapper {
.view--all__btn {
  width: 117px;
  height: 40px;
  border-radius: 10px;
  border: 1.5px solid $blue-magenta;
}

// }

.add-category-section {
  width: 100%;
  height: calc(100vh - 110px);
  background-image: url("../../images/newCategoryBg.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .plus--icon {
    width: 121px;
    height: 121px;
  }

  .add-detail--title {
    font-size: 35px;
    line-height: 39px;
    color: $primary-blue;
    font-weight: $font-light;
    padding-top: 35px;
  }
}

.add-contact-wrapper {
  padding: 0 50px;
}

.smart-device-wrapper {
  // min-width: 900px;
  background-color: #fff;
  border-radius: 20px;
  padding: 32px 32px 0 32px;

  .smart-device__detail {
    max-width: 1195px;
  }
}

.smart-document-wrapper {
  min-width: 500px;
}

.add-details-wrapper {
  .form__item {
    width: 100%;
    max-width: 890px;
    height: 48px;
    background-color: #f3f3f3;
    border: 1px solid $secondary-grey;
    outline: none;
    border-radius: 10px;
    // color: $primary-grey;
    color: $secondary-black;
    padding: 11px 16px;
  }

  .form__home-data {
    max-width: 1192px;
  }

  .street-address {
    max-width: 1194px;
  }

  ::placeholder {
    color: $primary-grey;
  }

  .textarea {
    min-height: 115px;
    resize: none;
    max-width: 1194px;
  }

  .document-wrapper {
    .title {
      font-size: 13px;
      padding: 0 0 12px 0;
    }
  }

  .tag-details-wrapper {
    .tag_name--list {
      // display: flex;
      padding-top: 15px;
      width: 100%;
      max-width: 1195px;

      .add-tag_name-wrapper {
        min-width: 112px;
        min-height: 40px;
        border: 1px dashed $primary-blue;
        border-radius: 10px;
      }
    }
  }

  .add-details-btn {
    min-width: 94px;
  }
}

.add__address-detail {
  border: 1px solid $secondary-grey !important;
}

.tag_name-wrapper {
  display: inline-block;
  min-width: 82px;
  min-height: 40px;
  padding: 11px 25px 9px 25px;
  border: 1px solid $secondary-grey;
  border-radius: 10px;
  color: $font-black-45;
  margin-right: 10px;
  margin-bottom: 10px;

  .tag-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $secondary-black;
    text-transform: capitalize;
  }

  .close--icon-wrapper {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    width: 20px;
    opacity: 0;
    height: 0px;
    background-color: $primary-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: height 0.3s all;

    .close--icon {
      width: 20px;
      height: 20px;
      // fill: #fff;

      // path {
      //   fill: $white-color;
      // }
    }
  }

  &:hover {
    .close--icon-wrapper {
      opacity: 1;
      width: 20px;
      height: 20px;
    }
  }
}

.tag--view {
  background-color: lightgray;
  box-shadow: 7px 8px 12px -3px #d3d3d3bd;
  border-radius: 20px;
  min-height: 40px;
}

.img-list {
  display: flex;
  align-items: center;

  .img-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .add-doc--icon {
      width: 44px;
      height: 44px;
    }

    .pdf--icon {
      width: 52px;
      height: 52px;
    }
  }

  .added-img {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      transition: all 0.3s linear;
    }

    .delete_icon-wrapper {
      right: -7px;
      top: -7px;

      &:hover {
        .added-img {
          pointer-events: none;
        }
      }
    }

    &:hover {
      border-color: transparent;
      // z-index: -1;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: -1;
      }

      .delete_icon-wrapper {
        height: 30px;
        opacity: 1;

        .delete--icon {
          padding-left: 2px;

        }
      }
    }
  }

  .add-img-wrapper {
    margin-bottom: 0;
    border: 1px solid $secondary-grey;
  }
}

.delete_icon-wrapper {
  display: flex;
  position: absolute;
  border-radius: 50%;
  background-color: $primary-blue;
  width: 30px;
  height: 30px;
  z-index: 3;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // height: 0;
  opacity: 0;
  transition: height 0.3s linear;

  .delete--icon {
    width: 15px;
    height: 15px;
    margin-left: -1px;

    path {
      fill: $white-color;
    }
  }
}

.new-category-popup {
  min-width: 386px;
  min-height: 220px;
  border-radius: 4px;
  background-color: $white-color;
  padding: 23px 33px 35px 33px;
  box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.15);

  .smart-device-label {
    .delete-button {
      width: 40px;
      height: 40px;

      .delete-option {
        width: 40px;
        height: 40px;
      }
    }
  }

  .category-title {
    font-size: 24px;
    line-height: 24px;
    font-weight: $font-bold;
  }

  .category-name {
    border: 1px solid $secondary-grey;
    background: #f3f3f3;
    border-radius: 10px;
    outline: none;
    width: 100%;
    min-height: 48px;
    margin-top: 23px;
    padding: 0 0 0 16px;
    font-size: 15px;
    line-height: 22px;
    color: $primary-black-color;
  }

  .add-category-btn {
    min-width: 159px;
  }

  .form-header--btn {
    .css-czbvgb-Control {
      width: 321px;
      height: 40px !important;
      padding: 0 15px 0 5px !important;
    }

    .css-13lyirf-IndicatorsContainer {
      background-size: 15px 9px;
      padding: 0px;
    }
  }
}

.new-document-popup {
  height: 500px;
}

// =======================popUp scsss=========================

.contact-all-data {
  table {
    width: 100%;
  }

  .contact-table-head {
    border-bottom: 1px solid #e6eaf0;
    margin-bottom: 15px;
    height: 60px;

    .sr-number {
      width: 300px;
      text-align: start;
    }

    .doc-name {
      width: 600px;
      text-align: start;
    }
  }

  .table__row-data {
    // border-bottom: 1px solid #E6EAF0;

    // height: 60px;
    height: 112px;
    border-bottom: 1px solid $white-smoke-grey;
    // min-width: 1500px;
    background-color: $white-color;

    .row-sr-number {
      width: 300px;
    }

    .row-doc-name {
      width: 600px;
    }
  }

  .table__row-data:hover .row-action__list {
    visibility: visible;
  }

  .table__row-data:hover .row-action {
    visibility: visible;
  }
}

.action-data {

  .add-data-logo,
  .delete-data-logo {
    width: 24px;
    height: 24px;
  }
}