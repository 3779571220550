.form-container {
  max-width: 1195px;
  width: 100%;
  height: auto;

  .input-data {
    .input-file--control {
      height: 48px;
      padding: 0;

      .select-file--control {
        height: 48px;
      }

      .selected-value--control {
        display: flex;
        align-items: center;
      }
    }

    .input-adds--control {
      width: 100%;
      padding-right: 15px;
    }
  }
}

.group-form__wrapper {
  background-color: $white-color;
  border-radius: 20px;
  padding: 32px;
}

.add-group--section {
  // position: absolute;
  // overflow: auto;
  // top: 125px;
  // right: 0;
  // width: calc(100% - 230px);
  // margin: 0 auto;
  // padding: 32px 50px 32px 50px;

  .add-group--container {
    .add-group--wrapper {
      background-color: $white-color;
      border-radius: 20px;
      padding: 32px;
    }
  }
}

.css-ad62uk-Control {
  div {
    height: 46px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
}

.css-1jwi8f4-singleValue {
  line-height: 26px;
  text-transform: capitalize;
}

.add-group--form {
  margin-top: 34px;

  .form-dropdown--sec {
    .form-dropdown--control {
      width: 582px;

      .css-1jwi8f4-singleValue {
        height: 46px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }

      .css-m0twm7-ValueContainer {
        .css-1jwi8f4-singleValue:focus-visible {
          height: 46px;
          display: flex;
          align-items: center;
        }
      }
    }

    .form-file--section {
      border: 1px dashed $dark-blue-color;
      width: 138px;
      height: 40px;
      // padding: 18px 28px;
      border-radius: 10px;
      background-color: $white-color;
    }
  }

  .cancel-btn {
    min-width: 98px;
    height: 48px;
    // padding: 13px 43px;
    border-radius: 10px;
    outline: none;
    border: 1.5px solid $blue-magenta;
    background-color: $white-color;
    color: $font-black-45;
    margin-right: 14px;
  }

  .submit-btn {
    min-width: 140px;
    height: 48px;
    padding: 0 20px;
    border-radius: 10px;
    outline: none;
    border: 1.5px solid $light-green-color;
    background-color: $light-green-color;
  }

  .house-field--section {
    margin-top: 34px;

    .house-table--header {
      .house-header--row {
        padding-bottom: 24px;
        border-bottom: 1px solid $white-smoke-grey;
      }

      // .house-header {
      //     width: 254px;
      // }

      .house-table--name {
        // width: 254px;
        width: 25%;
      }

      .house-table--number {
        width: 60%;
      }

      .house-table--action {
        width: 15%;

        .house-delete--icon {
          margin-left: 55px;
        }

        svg {
          height: 24px;
          width: 24px;

          path {
            fill: #000;
          }
        }
      }
    }

    .house-table--body {
      .house-table--row {
        padding: 20px 0;
        // border-top: 1px solid #f5f5f5;
        border-bottom: 1px solid $white-smoke-grey;

        .house-table--name {
          width: 25%;
          // width: 254px;
        }

        .house-table--number {
          width: 60%;
        }

        .house-table--action {
          width: 15%;

          .house-delete--icon {
            margin-left: 55px;
          }

          svg {
            height: 24px;
            width: 24px;

            path {
              fill: #000;
            }
          }
        }
      }
    }
  }

  .house-form--field {
    margin-top: 24px;

    .group-input--control {
      margin-right: 70px;
    }

    .add-house--deletebtn {
      width: 48px;
      height: 48px;
      border: 1px solid #ff4a55;
      border-radius: 10px;
      background-color: $white-color;
      padding: 0px 14px;

      svg {
        width: 16px;
        height: 24px;

        path {
          fill: #ff4a55;
        }
      }
    }

    .input-data {
      .input-adds--control {
        max-width: fit-content;
      }
    }

    div {
      .add-house--plusbtn {
        width: 48px;
        height: 48px;
        border: 1px solid $light-green-color;
        border-radius: 10px;
        background-color: $white-color;
        padding: 0px 14px;

        svg {
          width: 16px;
          height: 24px;

          path {
            fill: $light-green-color;
          }
        }
      }
    }
  }

  .custom-readonly {
    pointer-events: none;

    .css-1q23ovf-Control {
      max-width: 1195px;
    }

    .css-b62m3t-container {
      max-width: 1195px;
    }

  }

  .select-form--wrapper {
    margin: 40px 0;
  }

  .horizontal-line {
    max-width: 1195px;
    width: 100%;
    border-top: 1px solid $secondary-grey;
    margin: 60px 0;
  }

  .download-file--link {
    width: 582px;

    a {
      text-decoration: underline;
    }
  }
}

.add-house--deletebtn {
  width: 70px;
  height: 70px;
  border: 1px solid #ff4a55;
  border-radius: 50%;
  background-color: $white-color;
  padding: 0px 14px;

  // width: 28px;
  // height: 28px;

  path {
    fill: #ff4a55;
    // stroke: #FF4A55;
  }
}

.template-roll-out {
  height: 250px;
  justify-content: flex-start !important;
}

.template-data {
  width: 100%;
  align-items: end !important;
  // margin-bottom: 30px;
}

.template-close--icon {
  width: 40px;
  height: 40px;
  margin-right: 0;

  path {
    stroke: #000 !important;
  }
}

.preview-data {
  width: 100%;
  height: 100%;
  background-color: $primary-black-color;
  opacity: 0.8;
}

.preview-icon {
  width: 120px;
  height: 45px;
  flex-direction: row !important;

  .preview-close--icon {
    width: 40px;
    height: 40px;
    background-color: $white-color;
    border-radius: 50%;

    path {
      stroke: #000 !important;
    }
  }

  .download--button {
    width: 40px !important;
    height: 40px !important;

    .download--icon {
      width: 25px;
      height: 25px;
    }
  }
}

.preview-wrapper {
  align-items: flex-end !important;
  width: 100%;
  margin-right: 30px;
  margin-top: 20px;
  position: fixed;
  top: 0;
  right: 0;
}

.add-category {
  .add-category__button {
    min-width: 257px;
    height: 48px;
    border-radius: 10px;
  }
}

.sub-category {
  .add-category__button {
    min-width: 230px;
    height: 48px;
    border-radius: 10px;
    outline: none;
    border: 1.5px solid $blue-magenta;
    background-color: $white-color;
    color: $font-black-45;
    padding: 0 10px;
  }
}

.category--wrapper {
  max-width: 1178px;
  margin: 0 auto;
}

.category-box {
  // position: relative;
  width: 100%;
  max-width: 1178px;
  min-height: 86px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin: 0 auto 30px auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    text-transform: capitalize;
  }

  .category-show--wrapper {
    .eye-icon {
      svg {
        width: 24px;
        height: 16px;

        path {
          fill: $dark-blue-color;
        }
      }
    }

    .category-action {
      // display: none;

      .edit-icon {
        width: 24px;
        height: 24px;

        path {
          fill: $primary-grey;
        }
      }

      .delete-icon {
        width: 16px;
        height: 24px;

        path {
          fill: $primary-grey;
        }
      }
    }

    .custom-action {
      display: block !important;

      .edit-icon {
        width: 24px;
        height: 24px;

        path {
          fill: $primary-black-color;
        }
      }

      .delete-icon {
        width: 16px;
        height: 24px;

        path {
          fill: $primary-black-color;
        }
      }
    }

  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
    background: $primary-green;
    border-radius: 6px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 34px;
      height: 34px;
      fill: $white-color;
    }
  }
}

.decoration--icon {
  // position: absolute;
  height: 50px;
  right: -16px;
  top: 17px;
}

.form-header--btn {
  z-index: 3;
  overflow: auto;
  width: inherit;
  // margin: 0 auto;

  .form-file--section {
    border: 1px dashed $dark-blue-color;
    width: 138px;
    height: 40px;
    // padding: 18px 28px;
    border-radius: 10px;
    background-color: $white-color;
    // position: relative;
  }
}

.form-file--section {
  border: 1px dashed $dark-blue-color;
  width: 138px;
  height: 40px;
  // padding: 18px 28px;
  border-radius: 10px;
  background-color: $white-color;
  // position: relative;
}

.back-btn {
  width: auto;
  min-width: 138px;
  height: auto;
  min-height: 48px;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: $primary-green;
}

// .custom-dropdown--wrapper {
//     max-width: 400px;
// }

.react-switch-bg {
  width: 109px !important;
  height: 34px !important;
  border-radius: 17px !important;

  svg {
    width: 30px !important;
    height: 30px !important;
  }

  div {
    width: 80px !important;
    height: 34px !important;
  }
}

.category-header {
  a {
    &:active {
      color: $black-color;
    }

    &:visited {
      color: $black-color;
    }
  }

  .template-link {
    max-width: 153px;
  }

  svg {
    height: 10px;
    width: 11px;

    path {
      stroke-width: 2px;
      stroke: $blue-magenta;
    }
  }
}

.setInputDisabled {
  background-color: aliceblue !important;
  cursor: not-allowed;
}