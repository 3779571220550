.help--wrapper {
    position: fixed;
    top: 114px;
    right: 0px;
    background-color: #eae9e9;
    width: 500px;
    height: 100%;
    border-radius: 10px 0;
    box-shadow: #222 2px 2px 10px 4px;
    overflow-y: scroll;
    z-index: 101;

    .help-data--list {
        width: 100%;
        height: 100%;

    }

    .help-close--icon {
        width: 40px;
        height: 40px;

        path {
            stroke: black;
        }
    }
}