@import 'variable.scss';


.ql-toolbar {
  background: #f3f3f3;
  border-radius: 10px 10px 0 0;
  margin-left: 0 !important;
}

.ql-editor {
  height: 100%;
  min-height: 140px;
  background-color: #f3f3f3;
  color: $secondary-black;

  p {
    font-size: 16px;
  }
}

.ql-container {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.ql-editor.ql-blank::before {
  color: $secondary-black;
  font-style: normal !important;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

.ql-snow,
.ql-tooltip {
  left: 0px !important;
  margin-left: 15px;
}

.quill--view {
  .ql-toolbar {
    display: none !important;
  }

  .ql-container {
    border: none !important;
    border-radius: 0 !important;
  }

  .ql-editor {
    background: transparent !important;
    padding: 0;
    min-height: 40px;
  }
}

.sidebar-icons {
  width: 24px;
  height: 24px;
}

.dashboard--icon {
  width: 24px;
  height: 24px;

  path {
    fill: $medium-dark-grey-color;
    width: 24px;
    height: 24px;
  }
}

// .notification--icon {
//   width: 30px;
//   height: 30px;
// }

.tab-btn {
  background-color: $light-grey-color;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-width: 150px;
  height: 40px;
  padding: 0 20px;
  outline: none;
  border: none;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;

}

.tab-btn-active {
  background-color: $primary-green;
  color: $dark-blue-color;
}

.side--menu-list {
  padding: 20px 0 20px 20px;
  // border: 1px solid blue;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-left: 7px;
}

h4 {
  transition: ease-out 0.3s;
  display: none;
  // visibility: hidden;
  // z-index: -1;
  // padding-left: 20px;
  // color: $medium-dark-grey-color;
}

.side--menu-line {
  width: 6px;
  height: 26px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-grey-color;

  p {
    margin-bottom: 0;
  }

  .main-container {
    max-width: 1920px;
    margin: 0 auto;
  }

  .main-wrapper {
    // width: calc(100% - 230px);
    margin-left: auto;
    margin-top: 130px;

    .admin-header {
      max-width: 1670px;
    }
  }

  // Active SideBar
  .active-sidebar-link {
    .side--menu-line {
      background-color: $dark-blue-color;
    }

    .side--menu-list {
      // background-color: $light-grey-color;
      background-color: $dark-blue-color;

      .dashboard--icon {
        path {
          // fill: $dark-blue-color !important;
          fill: $white-color !important;
        }
      }

      .manager-icon--stroke {
        path {
          stroke: $white-color !important;
        }
      }

      h4 {
        // color: $dark-blue-color !important;
        color: $white-color !important;
      }
    }
  }

  .sidebar-close--toggle {
    position: fixed;
    top: 0;
    left: 0;
    height: $full-viewport-height;
    width: 68px;
    background-color: #fff;
    transition: all 0.2s;
    overflow-y: scroll;

    .side-logo--section {
      height: 110px;
      margin-bottom: 27px;

      .side-logo {
        width: 45px;
        height: 45px;
        margin: 10px 0 0 10px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .side-toggle {
        svg {
          width: 42px;
          height: 40px;
          top: 70px;
          right: 0;
        }
      }
    }

    .side--menu {
      ul {
        li {
          a {
            .side--menu-wrapper {
              .side--menu-line {
                width: 6px;
                height: 26px;
              }

              .side--menu-list {
                padding: 20px 0 20px 20px;
                // border: 1px solid blue;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
                margin-left: 7px;

                .sidebar-icons {
                  width: 24px;
                  height: 24px;
                }

                .dashboard--icon {
                  width: 24px;
                  height: 24px;

                  path {
                    fill: $medium-dark-grey-color;
                    width: 24px;
                    height: 24px;
                  }
                }

                h4 {
                  transition: ease-out 0.3s;
                  display: none;
                  // visibility: hidden;
                  // z-index: -1;
                  // padding-left: 20px;
                  // color: $medium-dark-grey-color;
                }
              }
            }
          }
        }

        .add-data__wrapper:nth-child(6),
        .add-data__wrapper:nth-child(7) {
          display: none;
          // background-color: #FF4A55 !important;
        }
      }
    }
  }

  .side-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: $full-viewport-height;
    width: 230px;
    background-color: #fff;
    transition: all 0.2s;
    overflow-y: scroll;

    .side-logo--section {
      height: 110px;
      margin-bottom: 27px;

      .side-logo {
        width: 80px;
        height: 70px;
        margin: 17px 0 0 13px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .side-toggle {
        svg {
          width: 42px;
          height: 40px;
          top: 70px;
          right: 0;
        }
      }
    }

    .side--menu {
      ul {
        li {
          .side--menu-wrapper {
            .side--menu-line {
              width: 6px;
              height: 26px;
            }

            .side--menu-list {
              padding: 20px;
              // border: 1px solid blue;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              margin-left: 10px;

              .sidebar-icons {
                width: 24px;
                height: 24px;
                margin-left: 20px;
              }

              .dashboard--icon {
                width: 24px;
                height: 24px;

                path {
                  fill: $medium-dark-grey-color;
                }
              }

              h4 {
                display: inline;
                transition: display 4s linear;
                padding-left: 20px;
                color: $medium-dark-grey-color;
              }
            }
          }

          // a {
          // }
        }

        .add-data__wrapper:nth-child(6),
        .add-data__wrapper:nth-child(7) {
          display: none;
          // background-color: #FF4A55 !important;
        }
      }
    }
  }

  .admin-panel {
    position: fixed;
    top: 0;
    // left: 0;
    right: 0;
    // width: calc(100% - 230px);
    width: inherit;
    padding: 32px 50px 32px 50px;
    background-color: $light-grey-color;
    z-index: 100;

    .admin-header {
      .admin-header--searchbar {
        height: 100%;

        input[type='text'] {
          max-width: 600px;
          border-radius: 50px;
          border: none;
          padding: 20px 0px 20px 76px;
          color: $medium-dark-grey-color;

          // &::placeholder {
          //   padding-left: 76px;
          // }

          &:focus {
            outline: none;
          }
        }

        svg {
          top: 18px;
          left: 36px;
          height: 24px;
          width: 24px;
        }
      }

      .admin-header--profilebar {
        // max-width: 600px;

        .admin-header--notificationbar {
          margin-right: 30px;

          .notification-icon {
            margin: 0 10px;
            border: 2px solid $medium-dark-grey-color;
            border-radius: 40px;
            height: 48px;
            width: 48px;

            svg {
              height: 26px;
              width: 26px;
            }

            .notification-toggle {
              height: 16px;
              width: 16px;
              background-color: $dark-blue-color;
              border-radius: 50%;
              top: -2px;
              right: 2px;
            }
          }
        }

        .admin-header--userprofile {
          min-width: 260px;

          .user-profile {
            margin-right: 13px;
            // border: 1.5px solid $medium-dark-grey-color;
            border-radius: 50%;
            height: 60px;
            width: 60px;
            background-color: #eaeaea;

            svg {
              width: 20px;
              height: 22px;
            }
          }

          .name-img {
            width: 100%;
            max-width: 48px;
            height: 48px;
            background-color: brown;
            border-radius: 50%;
            // margin-right: 16px;

            img {
              height: 100%;
              width: 100%;
              font-size: 12px;
              border-radius: 50%;
              border: 1px solid $secondary-blue;
            }
          }

          .name-imgs {
            width: 100%;
            max-width: 48px;
            height: 48px;
            background-color: brown;
            border-radius: 50%;
            // margin-right: 16px;

            img {
              height: 100%;
              width: 100%;
              font-size: 12px;
              border-radius: 50%;
              border: 0;
            }
          }

          .user-name {
            min-width: 130px;
            margin: 0 auto;
            margin-left: 15px;

            h4 {
              letter-spacing: 0.02em;
              text-transform: capitalize;
            }

            svg {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }
  }

  .profile-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin-left: 15px;
      margin-right: 25px;
      margin-bottom: 0;
    }
  }

  .options-wrapper {
    position: absolute;
    top: 100px;
    min-width: 230px;
    min-height: 150px;
    background-color: $white-color;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    padding: 25px 24px;
    border-radius: 8px;

    .menu {

      // width: 100%;
      .menu-item {
        display: flex;
        align-self: start;
        list-style-type: none;
        cursor: pointer;
      }

      hr {
        margin: 0;
      }

      .icon-wrapper {
        min-width: 40px;

        .person--icon {
          width: 18px;
          height: 20px;
        }
      }
    }
  }

  .notification-wrapper {
    position: absolute;
    top: 70px;
    min-width: 230px;
    min-height: 150px;
    background-color: $white-color;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    padding: 0px 40px 25px 24px;
    border-radius: 8px;

    .menu {

      // width: 100%;
      .menu-item {
        display: flex;
        align-self: start;
        list-style-type: none;
        cursor: pointer;
      }

      hr {
        margin: 0;
      }

      .icon-wrapper {
        min-width: 40px;

        .person--icon {
          width: 18px;
          height: 20px;
        }
      }
    }
  }

  // .user-action {
  //   .user-table--action {
  //     // visibility: hidden;
  //   }

  //   // &:hover {
  //   //   .user-table--action {
  //   //     visibility: visible;
  //   //   }
  //   // }
  // }

  .dashboard-container {
    padding: 0px 50px 32px 50px;

    .dashboard-wrapper {
      .dashboard-list--wrapper {
        background-color: #fff;
        padding: 0 21px 0 35px;
        width: 100%;
        max-width: 533px;
        height: 209px;
        border-radius: 20px;

        .dashboard-list--user {
          .list-user--icon {
            height: 54px;
            width: 56px;
            // background-color: #27377326;
            background-color: rgba(39, 55, 115, 0.15);
            border-radius: 5px;
            margin-right: 24px;

            svg {
              height: 27px;
              width: 24px;

              path {
                fill: $dark-blue-color;
              }
            }
          }
        }

        .dashboard-list--userdetails {
          .list-user--detailsicon {
            height: 48px;
            width: 48px;
            // background-color: #27377326;
            background: rgba(102, 102, 102, 0.3);
            border-radius: 5px;

            svg {
              height: 11px;
              width: 11px;
            }
          }
        }
      }
    }
  }

  .user-list--section {
    // position: absolute;
    overflow: auto;
    // top: 134px;
    // right: 0;
    // width: calc(100% - 230px);
    width: inherit;
    margin: 0 auto;
    padding: 0px 50px 32px 50px;

    .user-list--container {
      .user-list--wrapper {
        background-color: #fff;
        border-radius: 20px;
        padding: 32px 0px 0px;

        .user-history--header {
          padding: 0 32px;

          .user-title {
            a {
              text-decoration: underline;
            }

            svg {
              min-width: 10px;
              height: 11px;
            }
          }

          .adduser-btn--wrapper {
            min-width: 240px;

            .add-user--btn {
              background-color: $primary-green;
              border-radius: 10px;
              min-width: 200px;
              height: 48px;
              padding: 0 20px;
              outline: none;
              border: none;
            }

            // .reset-btn--wrapper {
            //   min-width: 100px;
            // }

            .filter-icon {
              min-width: 48px;
              height: 48px;
              border-radius: 10px;
              box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
              border: none;
              background-color: $dark-blue-color;
              margin: 0 10px;

              svg {
                width: 28px;
                height: 28px;
                margin: 0 auto;

                path {
                  fill: $white-color;
                }
              }
            }

            &:active {
              border: none;
            }
          }

          .btn--wrapper {
            min-width: 360px;
          }
        }

        .addgroup-btn--wrapper {
          min-width: 278px;

          .add-group--btn {
            background-color: $primary-green;
            border-radius: 10px;
            min-width: 208px;
            height: 48px;
            padding: 0 20px;
            outline: none;
            border: none;
          }

          .add-user--btn {
            background-color: $primary-green;
            border-radius: 10px;
            min-width: 184px;
            height: 48px;
            padding: 0 20px;
            outline: none;
            border: none;
          }

          .reset-ads--wrapper {
            max-width: 80px;

            .reset-button {
              background: transparent;
            }

            .filter-icon {
              min-width: 48px;
              height: 48px;
              border-radius: 10px;
              box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
              border: none;
              background-color: $dark-blue-color;
              margin: 0 10px;

              svg {
                width: 28px;
                height: 28px;
                margin: 0 auto;

                path {
                  fill: $white-color;
                }
              }
            }
          }

          .filter-icon {
            min-width: 48px;
            height: 48px;
            border-radius: 10px;
            box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
            border: none;
            background-color: $dark-blue-color;
            margin: 0 10px;

            svg {
              width: 28px;
              height: 28px;
              margin: 0 auto;

              path {
                fill: $white-color;
              }
            }
          }

          &:active {
            border: none;
          }
        }

        .addhouse-btn--wrapper {
          min-width: 254px;

          .add-house--btn {
            min-width: 184px;
            background-color: $primary-green;
            border-radius: 10px;
            height: 48px;
            padding: 0 20px;
            outline: none;
            border: none;
          }

          .filter-icon {
            min-width: 48px;
            height: 48px;
            border-radius: 10px;
            box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
            border: none;
            background-color: $dark-blue-color;
            margin: 0 10px;

            svg {
              width: 28px;
              height: 28px;
              margin: 0 auto;

              path {
                fill: $white-color;
              }
            }
          }

          &:active {
            border: none;
          }
        }
      }

      // .add-group--btn {
      //   min-width: 208px;
      //   height: 60px;
      // }

      .user-table {
        overflow-x: auto;
        overflow-y: hidden;

        table {
          width: -webkit-fill-available;
          overflow-x: auto;
          table-layout: fixed;
          border-collapse: collapse;

          th,
          td {
            &:first-child {
              padding-left: 32px !important;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
            }

            &:last-child {
              padding-right: 32px !important;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
            }
          }

          .user-table--header {
            tr {
              padding: 0 32px;
              // border-bottom: 1px solid $white-smoke-grey;
              // min-width: 1500px;
              background-color: $white-color;

              th {
                padding: 49px 0 25px 0;
                text-align: start;
              }

              .user-table--serialno {
                // width: 10%;
                width: 150px;
                // text-align: center;
              }

              .user-table--name {
                // width: 20%;
                width: 250px;

                .name-txt {
                  text-transform: capitalize;
                }
              }

              .user-table--email {
                width: 400px;
                // width: 20%;
              }

              .user-table--link {
                width: 200px;
                // width: 28%;
              }

              .user-table--status {
                // width: 12%;
                width: 150px;
              }

              .user-table--action {
                // width: 10%;
                width: 50px;
              }

              .user-table--location {
                width: 200px;
              }

              .user-table--group {
                width: 300px;
              }

              .user-table--house {
                width: 200px;
              }

              .user-template--status {
                width: 300px;
              }

              .user-table--status {
                width: 200px;
              }

              .user-table--template__name {
                width: 250px;
              }

              .user-table--start-date {
                width: 300px;
              }

              .user-table--end-date {
                width: 300px;
              }

              .user-table--frequency {
                width: 200px;
              }

              .user-table--status {
                width: 200px;
              }

              .user-table--house__name {
                width: 300px;
              }

              .user-table--group__name {
                width: 300px;
              }
            }
          }

          .user-table--body {
            &:hover {
              .user-action {
                cursor: pointer;
              }
            }

            tr {
              height: 112px;
              padding: 0 32px;
              border-top: 1px solid $white-smoke-grey;
              // min-width: 1500px;
              background-color: $white-color;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
              pointer-events: none;

              .user-table--location {
                width: 200px;
                text-transform: capitalize;
                // word-break: break-all;
                overflow-wrap: break-word;
              }

              .user-table--group {
                width: 400px;
              }

              .user-table--house {
                width: 200px;
                text-transform: capitalize;
              }

              .group-filter {
                width: 300px;
                pointer-events: fill;
              }

              .user-table--status {
                width: 200px;
                pointer-events: fill;
              }

              .user-table--template__name {
                width: 250px;
                text-transform: capitalize;
                // word-break: break-all;
                overflow-wrap: break-word;
                cursor: pointer;
                pointer-events: all;
              }

              .user-table--group__name {
                width: 300px;
              }

              .user-table--house__name {
                width: 300px;
              }

              .user-template--active {
                width: 300px;
                text-align: center;
                pointer-events: fill;
              }

              .house-count,
              .group-count {
                pointer-events: fill;
              }
            }

            .user-table--serialno {
              width: 150px;
              border-bottom-left-radius: 50px;
            }

            .user-table--name {
              width: 250px;
              cursor: pointer;
              pointer-events: all;

              .name-txt {
                // margin-left: 16px;

                h2 {
                  text-transform: capitalize;
                  margin-right: 10px;
                  // word-break: break-word;
                  overflow-wrap: break-word;
                }
              }

              .name-img {
                min-width: 48px;
                height: 48px;
                background-color: brown;
                border-radius: 50%;

                img {
                  // height: 100%;
                  // width: 100%;
                  // font-size: 12px;
                  border-radius: 50%;
                }
              }
            }

            .user-table--email {
              // width: 20%;
              width: 300px;
              // word-break: break-all;
              overflow-wrap: break-word;
              padding-right: 20px;
            }

            .house-count {
              pointer-events: fill;
            }

            .user-table--groups {
              width: 250px;
              // cursor: pointer;
              // pointer-events: fill;
            }

            .user-table--link {
              // width: 28%;
              width: 350px;

              .link-data {
                color: $dark-blue-color;
              }

              .links {
                text-decoration: underline;
              }

              .links:hover {
                color: $primary-green !important;
              }

              .active-link {
                color: $primary-green;
              }
            }

            .user-table--status {
              // width: 12%;
              width: 200px;

              .active-btn {
                background-color: $primary-green;
                border-radius: 40px;
                color: $dark-blue-color;
                // padding: 15px 32px;
                width: 110px;
                height: 48px;
              }

              .unactive-btn {
                background-color: #ff4a55;
                border-radius: 40px;
                // padding: 15px 32px;
                width: 110px;
                height: 48px;
                color: #fff;
              }
            }

            .user-table--action {
              // width: 10%;
              width: 200px;
              pointer-events: fill;

              svg {
                width: 24px;
                height: 24px;
                margin: 0 10px;

                path {
                  fill: $medium-dark-grey-color;
                }
              }

              .add-notification-logo {
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    .user-list--pagination {
      margin-top: 67px;

      .pagination-btn {
        .previous {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;

          a {
            padding: 20px;

            svg {
              width: 20px;
              height: 13px;

              path {
                fill: $medium-dark-grey-color;
              }
            }
          }
        }

        .next {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;

          a {
            svg {
              width: 20px;
              height: 13px;

              path {
                fill: $medium-dark-grey-color;
              }
            }
          }
        }

        .pagination-prev--btn {
          width: 50px;
          height: 50px;
          border-radius: 50%;

          svg {
            width: 20px;
            height: 13px;

            path {
              fill: $medium-dark-grey-color;
            }
          }
        }

        .pagination-page--number {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: $light-grey-color;
          border: 2px solid $medium-dark-grey-color;
          // margin-right: 16px;
          margin: 0 8px;

          a {
            padding: 20px;
            border-radius: 50%;
            font-size: 1.125rem;
            line-height: 22px;
          }
        }

        .active {
          background-color: $dark-blue-color !important;
          color: #fff !important;
          border: 2px solid $dark-blue-color !important;
        }

        .pagination-page--number-third {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: $light-grey-color;
          border: 2px solid $medium-dark-grey-color;

          a {
            padding: 20px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.secondary-side--wrapper {
  // margin-top: 5px;
  // margin-left: 5px;
  // position: fixed;
  // top: 40px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 68px;
  height: 100vh;

  svg {
    position: fixed;
    top: 43px;
    width: 40px;
    height: 42px;
  }
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

.react-datetime-picker__inputGroup__input {
  outline: none;
}

.show--error {
  font-size: 12px;
  line-height: 12px;
  color: red;
  margin-top: 10px;
  margin-left: 5px;
  opacity: 1;
  visibility: visible;
}

.select__clear-indicator {
  display: none !important;
}

.break,
.break-me {
  color: black;
}

.active-dashboard {
  &:active {
    color: $primary-black-color;
  }

  &:focus {
    color: $primary-black-color;
  }

  &:visited {
    color: $primary-black-color;
  }

  &:hover {
    color: $primary-black-color;
  }
}

.builderdropdown {
  width: 300px;
}

.builder-custom {
  width: 100%;
  min-width: 400px;

  .builder-select {
    .css-ad62uk-Control div {
      text-transform: capitalize;
    }
  }
}

.asc-order,
.desc-order,
.sort-order {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.sort-order {
  margin-left: 10px;
}

.search-field {
  max-width: 500px;

  .search-input {
    max-width: 500px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid $secondary-grey;
    padding-left: 20px;
    outline: none;
    font-size: 16px;
    line-height: 52px;
    background-color: transparent;
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.search-icon {
  right: 20px;
  top: 15px;

  .search-logo {
    width: 20px;
    height: 20px;
  }
}

.reset-btn--wrapper {
  margin: 0 10px;
}

.add-manager--wrapper {
  width: auto;

  .filter-icon {
    min-width: 48px;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
    border: none;
    background-color: $dark-blue-color;
    margin: 0 10px;

    svg {
      width: 28px;
      height: 28px;
      margin: 0 auto;

      path {
        fill: $white-color;
      }
    }
  }

  &:active {
    border: none;
  }
}

.form-dropdown--sec {
  max-width: 528px;
}

.template-data--wrapper {
  background-color: #8bd8bd73;
  border-radius: 4px;
  padding: 10px;
}

.form-recurring--control {
  position: relative;
  padding-left: 35px;
  margin: 12px auto;
  font-size: 15px;
  line-height: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 22px;
  width: 100%;
  max-width: 280px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      border: 2px solid #8bd7bc;
      background-color: white;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    // background-color: #eee;
    border-radius: 50%;
    border: 2px solid #a6a6a6;

    &:after {
      content: "";
      position: absolute;
      display: none;

      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      border-radius: 90%;
      background: #8bd7bc;
    }
  }

  &:hover {
    input~.checkmark {
      background-color: #fff;
    }
  }
}

#pdf-controls {
  display: none;
}

#react-doc-viewer {
  width: 800px;
  height: 800px;
}

#proxy-renderer {
  width: 800px;
  height: 800px;
}

#msdoc-renderer {
  height: 800px;
}

.add-manager-wrapper {
  .add-user--btn {
    background-color: $primary-green;
    border-radius: 10px;
    min-width: 170px;
    height: 48px;
    padding: 0 20px;
    outline: none;
    border: none;
  }
}

.css-ad62uk-Control {
  text-transform: capitalize;
}

.multi-select--option,
.multi-select {
  .css-1q23ovf-Control {
    max-width: 1195px;
    border: none;
    height: auto !important;
    margin-top: 0;
  }

  .css-uznncy {
    display: none !important;
  }

  .css-1dl3pg3 {
    border: none;
  }

  .css-1asbeb {
    display: none;
  }

  .css-1fa7ebs-ValueContainer {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: $primary-black-color;
  }

  .css-b62m3t-container {
    pointer-events: none;
  }

  .select__indicators {
    display: none;
  }

  .css-1uba960-multiValue {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  .css-ad62uk-Control {
    max-width: 1195px;
    border: none;
    height: auto !important;
    margin-top: 0;
    padding: 0px !important;
  }

  .css-ivxax7-IndicatorsContainer {
    display: none;
  }

  .css-m0twm7-ValueContainer {
    color: $primary-black-color;
  }

  .css-yv3kgn {
    border: none !important;
    background-color: transparent !important;
    height: auto !important;
    outline: none;
    resize: none;
    padding-left: 0 !important;
    border-radius: 0px !important;
  }
}


.input-read-only {
  border: none !important;
  background-color: transparent !important;
  height: auto !important;
  outline: none;
  resize: none;
  padding-left: 0 !important;
  border-radius: 0px !important;

  .select-file--control {
    display: none !important;
  }

  .selected-value--control {
    margin-left: 0 !important;
  }

  .input-file--control {
    background-color: transparent !important;
  }


}

.start-date,
.end-date,
.view-page {
  max-width: 150px;
}

.custom-frequency {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.view-description {
  height: 100%;
  min-height: 40px !important;

  .ql-toolbar {
    display: none !important;
    border: none !important;
  }

  .ql-container {
    border: none !important;
  }

  .ql-editor {
    background: transparent;
    padding: 0px !important;
    height: 100%;
    min-height: 40px !important;
  }
}

.remove--button {
  right: 16px;
  top: 10px;

  .preview-link--icon {
    width: 30px;
    height: 30px;

    path {
      stroke: black;
    }
  }
}

.preview-link--icon {
  width: 30px;
  height: 30px;

  path {
    stroke: black;
  }
}

.add-house--plusbtn {
  width: 32px;
  height: 32px;
  border: 1px solid $primary-green;
  border-radius: 5px;
  background-color: #fff;
  padding: 0px 4px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: $primary-green;
    }
  }
}

.view-horizontal {
  border: 0;
  border-top: 1px solid #eee;
  border-radius: 2px;
}

.img-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .add-doc--icon {
    width: 44px;
    height: 44px;
  }

  .pdf--icon {
    width: 52px;
    height: 52px;
  }
}

.help--button {
  width: 30px;
  height: 30px;

  .help--icon {
    width: 30px;
    height: 30px;
  }
}

.owner-detail--wrapper {
  align-items: baseline !important;
}

.old-owner--detail {
  // background: #8bd8bd54;
  // border: 1px solid #00000036;
  // padding: 5px;
  // border-radius: 5px;
  height: fit-content;
  // box-shadow: 0 9px 100px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  .owner-detail {
    list-style-type: disc;
  }
}

.past-owner--button {
  min-width: 184px;
  // background-color: $primary-green;
  // border-radius: 10px;
  text-decoration: underline;
  // height: 30px;
  // padding: 0 20px;
  outline: none;
  border: none;
}

.manager-house--detail,
.manager-group--detail,
.notification-group--detail,
.notification-house--detail,
.tag-view--detail {
  // background: #8bd8bd54;
  border: 1px solid #00000036;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 9px 100px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}