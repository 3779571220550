@font-face {
  font-family: "Now";
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/now-regular.otf");
}

@font-face {
  font-family: "Now";
  font-display: auto;
  font-weight: 300;
  src: url("../fonts/now-light.otf");
}

@font-face {
  font-family: "Now";
  font-display: auto;
  font-weight: 500;
  src: url("../fonts/now-medium.otf");
}

@font-face {
  font-family: "Now";
  font-display: auto;
  font-weight: 700;
  src: url("../fonts/now-bold.otf");
}
