@media screen and (max-width:1821px) {
  body .dashboard-container .dashboard-wrapper .dashboard-list--wrapper {
    max-width: 500px;
  }
}

@media screen and (max-width:1480px) {

  .login-wrapper {
    .content-wrapper {
      .form-wrapper {
        padding-top: 35px !important;

        .login--title {
          padding-top: 20px !important;
        }

        .forgot-wrapper {
          margin-bottom: 25px;
          margin-top: 20px !important;
        }
      }

      .sign-page--wrapper {
        margin-top: 160px !important;
      }

      .footer-wrapper {
        padding-bottom: 30px;
      }
    }

    .form__item {
      margin-top: 15px !important;
    }
  }

}

@media screen and (max-width: 1450px) {
  .font--size__18 {
    font-size: 1rem !important;
  }

  .font--size__50 {
    font-size: 34px !important;
  }

  .body {
    .user-list--section {
      .user-list--container {
        .user-table table {
          .user-table--body {
            .user-table--action svg {
              margin-right: 20px !important;
              margin-left: 0;
            }
          }
        }
      }
    }

  }

  body .dashboard-container .dashboard-wrapper .dashboard-list--wrapper {
    max-width: 370px;
  }

  // .action-data .add-data-logo,
  .action-data .delete-data-logo {
    margin-right: 20px !important;
  }
}