%box-size--border-box {
  box-sizing: border-box;
}

%d--inline-block {
  display: inline-block;
}

%transition--default {
  @include transition(all, 0.5s, ease);
}

%flex {
  display: -webkit-flex !important;
  /* autoprefixer: off */
  display: -webkit-box !important;
  /* autoprefixer: on */
  display: -moz-flex !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

%flex--row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

%flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

%flex--row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

%flex-column--reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

%flex--wrap {
  flex-wrap: wrap;
}

%justify-content--center {
  -webkit-box-pack: center;
  justify-content: center;
}

%justify-content--start {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

%justify-content--end {
  -webkit-box-pack: end;
  justify-content: flex-end;
}

%justify-content--between {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

%justify-content--around {
  justify-content: space-around;
}

%align-items--center {
  -webkit-box-align: center;
  align-items: center;
}

%align-items--end {
  -webkit-box-align: end;
  align-items: flex-end;
}

%align-items--start {
  -webkit-box-align: start;
  align-items: flex-start;
}

%align-items--baseline {
  -webkit-box-align: baseline;
  align-items: baseline;
}

%align-content--baseline {
  -ms-flex-line-pack: baseline;
  align-content: baseline;
}

%align-content--center {
  -webkit-box-align: center;
  align-content: center;
}

%align-content--end {
  -webkit-box-align: end;
  align-content: flex-end;
}

%align-content--start {
  -webkit-box-align: start;
  align-content: flex-start;
}

%object-fit--cover {
  -o-object-fit: cover;
  object-fit: cover;
}

%object-fit--contain {
  -o-object-fit: contain;
  object-fit: contain;
}